<template>
  <div id="analysisPage" width="100%" height="100%">
    <iframe
      frameborder="0"
      width="100%"
      height="650px"
      src="https://docs.google.com/document/d/e/2PACX-1vSdzcgvBsdvxBfcshUkhXBlM5HlMb8Tvq_HQTi1p9PCa76HPFk67c9SkPiARWJ6bQL3NBGBEvvdkqf9/pub?embedded=true"
    ></iframe>

    <div>
    <br/><br/><br/>
    </div>
  </div>
</template>
<script>
export default {
};
</script>